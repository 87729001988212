@function get-color-theme($color) {
  @if lightness($color) < 15% {
    @return "dark";
  } @else {
    @return "light";
  }
}

@function breakpoint-infix($name, $breakpoints: default) {
  @if $breakpoints == default {
    $breakpoints: $responsive;
  }
  $min: map-get($breakpoints, $name);
  @return if($min != 0, "#{$name}-", "");
}
